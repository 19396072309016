<template>
<v-row no-gutters>
    <v-col cols="12" md="12">
      <v-toolbar dark color="primary pl-2 pr-2" dense>
        <v-toolbar-title>ပစ္စည်းပို့မည်</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('closeDialog')">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-toolbar>
    </v-col>
    <v-col offset-md="4" cols="12" md="4" class="pa-3">
      <v-card outlined class="pa-3">
        <span class="primary--text">ပစ္စည်းပေးပို့မည့် အချက်အလက်များ ဖြည့်ရန်</span>
        <v-text-field v-model="user.phone" label="ပစ္စည်းပေးသူ ဖုန်းနံပါတ်*" dense outlined filled class="mt-3"></v-text-field>
        <v-text-field v-model.number="order.orderCount" label="လမ်းကြောင်း အရေအတွက်" type="number" dense outlined filled ></v-text-field>
        <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="290px"
              v-model="fromMenu"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="fromDate"
                  label="ပစ္စည်းလာကောက်ရမည့် နေ့"
                  filled
                  outlined
                  readonly
                  v-on="on"
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromPicker"
                @input="fromMenu = false"
                :min="new Date().toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>
        <v-textarea rows="2" v-model="user.address" label="လာယူရမည် လိပ်စာ*" dense outlined filled ></v-textarea>
        <v-textarea v-model="order.remark" rows="2" label="မှတ်ချက်" dense outlined filled></v-textarea>
        <v-row no-gutters>
        <v-col cols="12" md="12" class="text-right">
          <v-btn color="primary" @click="saveOrder()" v-if="saveOrupdate=='SAVE'">လုပ်ဆောင်ပါမည်</v-btn>
          <v-btn color="primary" @click="saveOrder()" v-else>ပြင်ဆင်ပါမည်</v-btn>
        </v-col>
        </v-row>
      </v-card>
    </v-col>
</v-row>
</template>
<script>
import uaService from "../service/UserAccountService";
import osService from "../service/OsService";
export default {
  data: () => ({
    user : {},
    fromPicker: new Date().toISOString().substr(0, 10),
    fromMenu: false,
    fromDate: "",
    saveOrupdate:"SAVE",
    order:{},
  }),
   props: {orderDto:Object},
  mounted: function() {
    this.fromDate = this.formatDate(this.fromPicker);
   if(this.orderDto == undefined){
    this.saveOrupdate = "SAVE";
      this.osDetailMethod();
   }else{
      this.saveOrupdate = "UPDATE";
      this.fromDate = this.orderDto.pickupDate;
      this.order.orderCount = this.orderDto.orderCount;
      this.user.address=this.orderDto.osAddress;
      this.user.phone = this.orderDto.osPhone;
      this.order.remark = this.orderDto.remark;
   }
    
  },
  methods: {
    osDetailMethod: function () {
        uaService
        .getUserAccountsDetail()
        .then((response) => {
          this.user = Object.assign({},response);
        })
        .catch((error) => {
          this.$swal("Fail!", error.response.data.message, "error");
        });
    },
    formatDate(fromPicker) {
      const [year, month, day] = fromPicker.split("-");
      return `${day}-${month}-${year}`;
    },
    saveOrder:function(){
      if(this.saveOrupdate == "SAVE"){
        this.order.osPhone = this.user.phone;
        this.order.osAddress = this.user.address;
        this.order.osName = this.$store.state.userAccount.profileName;
        this.order.pickupDate = this.fromDate;
      osService
        .addOrder(this.order)
        .then((response) => {
          this.$emit('refreshList');
          this.$emit('closeDialog');
          //this.saveOrupdate = "UPDATE";
          this.$swal({
                  title: "Successful",
                  text: "Save,Success!",
                  type: "success",
                  timer: 500,
                });

        })
        .catch((error) => {
          this.$swal("Fail!", error.response.data.message, "error");
        });
      }else{
        this.order.orderId = this.orderDto.orderId;
        this.order.osPhone = this.user.phone;
        this.order.osAddress = this.user.address;
        this.order.osName = this.$store.state.userAccount.profileName;
        this.order.pickupDate = this.fromDate;
        this.order.receivedDate = this.orderDto.receivedDate;
        this.order.date = this.orderDto.date;
        osService
        .updateOrder(this.order)
        .then((response) => {
          this.$emit('refreshList');
          this.$emit('closeDialog');
          this.saveOrupdate = "SAVE";
          this.$swal({
                  title: "Successful",
                  text: "Save,Success!",
                  type: "success",
                  timer: 500,
                });
        })
        .catch((error) => {
          this.$swal("Fail!", error.response.data.message, "error");
        });
      }
    },
  },
  watch: {
     fromPicker() {
      this.fromDate = this.formatDate(this.fromPicker);
    },
  },
  components: {}
};
</script>
<style scoped>

</style>