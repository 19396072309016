import axios from "../config"

class OsService{
    axios;
    constructor(){
        this.axios = axios;
    }
    
    getOsStatusList() {
        let url = `/os/statuslist`
        return axios.get(url,{
            
        }).then(request => request.data);
      }
      getOsDeliStatusList() {
        let url = `/os/statuslist/deli`
        return axios.get(url,{
            
        }).then(request => request.data);
      }
      getItemByStatus(fromDate,toDate,status) {
        let url = `/os/item/status/order`
        return axios.get(url,{
          params:{
            fromDate,toDate,status
          }
        }).then(request => request.data);
      }  
      getItemDetailById(itemId) {
        let url = `os/item/detail/${itemId}`
        return axios.get(url).then(request => request.data);
      } 
      addOrder(order) {
        let url = `os/order`;
        return this.axios.post(url, order).then(request => request.data);
      }
      updateOrder(order) {
        let url = `os/order/${order.orderId}`;
        return this.axios.put(url, order).then(request => request.data);
      }
      
}

const service = new OsService();
export default service;