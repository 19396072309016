<template>
  <v-app>
    <div>
      <v-app-bar
        v-if="!hiddenToolbar"
        fixed
        app
        color="primary"
        dense
        dark
        class="hidden-print-only"
      >
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          v-if="!showComputer"
        ></v-app-bar-nav-icon>
        <v-btn v-if="showComputer" icon text @click="$router.back()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title ><v-btn text @click="changeRouter('/os/home')">Home</v-btn> </v-toolbar-title>
        <div class="flex-grow-1"></div>
        <v-toolbar-items>
          <!-- <v-btn v-if="showComputer"
          text  style="text-transform: none" @click="dialogOsOrder=true">ပစ္စည်းပို့မည်</v-btn> -->
          <v-btn v-if="showComputer"
            text @click="changeRouter('/os/dailychecklist')"  style="text-transform: none">Daily Check List</v-btn>
          <v-btn  v-if="showComputer"
            text @click="changeRouter('/os/trackinglist')"  style="text-transform: none">Tracking List</v-btn>
         
          <v-menu offset-y left v-if="showComputer">
           <template v-slot:activator="{ on }">
              <v-btn text v-on="on">
                <span class="ml-2 d-none d-sm-flex" style="text-transform: none"
                  >
                  {{loginUser.profileName }}
                  </span
                >
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(item, index) in profileList"
                  :key="index"
                  @click="changeRouter(item.value)"
                  
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-toolbar-items>
      </v-app-bar>
      <v-navigation-drawer v-model="drawer" fixed temporary>
        <v-list>
          <v-list-item class="px-2">
            <v-list-item-avatar>
              <v-img src="/info/logo.png"></v-img>
            </v-list-item-avatar>
            <v-list-item-title class="text-h6">
                {{loginUser.profileName }}
              </v-list-item-title>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>
        
        <v-list
          nav
          dense
        >
        <v-list-item 
        @focus="selectIndex = 'home'"
        :style="{
                  backgroundColor:
                    selectIndex == 'home' ? '#def3ff' : 'transparent',
                }"
                @click="changeRouter('/os/home')">
        <v-list-item-icon >
          <v-icon>mdi-home-floor-1</v-icon>
        </v-list-item-icon>

        <v-list-item-title >Home</v-list-item-title>
        </v-list-item>
      <v-list-item 
        @focus="selectIndex = 'dailychecklist'"
        :style="{
                  backgroundColor:
                    selectIndex == 'dailychecklist' ? '#def3ff' : 'transparent',
                }"
                @click="changeRouter('/os/dailychecklist')">
        <v-list-item-icon >
          <v-icon>mdi-marker-check</v-icon>
        </v-list-item-icon>

        <v-list-item-title >Daily Check List</v-list-item-title>
        </v-list-item>

        <v-list-item 
        @focus="selectIndex = 'trackinglist'"
        :style="{
                  backgroundColor:
                    selectIndex == 'trackinglist' ? '#def3ff' : 'transparent',
                }"
                @click="changeRouter('/os/trackinglist')">
        <v-list-item-icon >
          <v-icon>mdi-radar</v-icon>
        </v-list-item-icon>

        <v-list-item-title >Tracking List</v-list-item-title>
        </v-list-item>
        <v-list-group
        prepend-icon="mdi-face"
        
      >
        <template v-slot:activator>
          <v-list-item-title>My Profile</v-list-item-title>
        </template>
          <v-list-item
            v-for="(item, i) in profileList"
            :key="i"
            link
            @click="changeRouter(item.value)"
            @focus="selectIndex = item.value"
            :style="{
                  backgroundColor:
                    selectIndex == item.value ? '#def3ff' : 'transparent',
                }"
          >
          <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item>
      </v-list-group>
        </v-list>
        <template v-slot:append>
          <div class="pa-2">
            <v-btn
              class="red--text"
              block
              rounded
              outlined
              @click="changeRouter('/logout')"
            >
              <v-icon>mdi-logout</v-icon>Logout
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>
      <!-- <v-content> -->
        <div class="mt-12 hidden-print-only"></div>
        <v-container fluid class="mt-0 pa-0">
        <v-progress-linear
          v-if="loading"
          fixed
          :indeterminate="loading"
          color="primary"
        ></v-progress-linear>
        <router-view :hideToolbar="hideToolbar" />
      </v-container>
      <!-- </v-content> -->
      <div>
        <v-dialog v-model="logoutModel" persistent max-width="350">
          <v-card>
            <v-card-title class="title">ထွက်ရန် သေချာပြီလား?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" text @click="logoutModel = false"
                >မလုပ်ပါ</v-btn
              >
              <v-btn color="primary" depressed @click="logout">ထွက်မည်</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <div>
        <v-dialog v-model="passwordModel" persistent max-width="400">
          <v-form ref="form" v-model="valid">
            <v-card>
              <v-card-title class="headline"
                >စကား၀ှက် ပြောင်းတော့မှာလား?</v-card-title
              >
              <v-card-text class="pb-0">
                <v-text-field
                  :append-icon="showOld ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showOld ? 'text' : 'password'"
                  @click:append="showOld = !showOld"
                  :rules="passwordOldRule"
                  v-model="password.old"
                  label="Old Password"
                  name="oldPassword"
                  placeholder=" "
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  :append-icon="showNew ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showNew ? 'text' : 'password'"
                  @click:append="showNew = !showNew"
                  :rules="passwordNewRule"
                  v-model="password.new"
                  label="New Password"
                  name="newPassword"
                  placeholder=" "
                  outlined
                  dense
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="cancelChangePassword"
                  >မပြောင်းတော့ပါ</v-btn
                >
                <v-btn
                  color="primary"
                  :disabled="!valid"
                  depressed
                  @click="changePassword"
                  >ပြောင်းမည်</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
        <v-bottom-sheet v-model="dialogOsOrder" fullscreen scrollable>
          <v-sheet class="fullscreen">
            <osOrderDialog
              @closeDialog="dialogOsOrder=false"
              :noti="noti"
              @refreshList="getOsStatus()"
            />
          </v-sheet>
        </v-bottom-sheet>
      </div>
    </div>
  </v-app>
</template>

<script>
import osOrderDialog from "@/components/OsOrderDialog.vue";
import axios from "../src/config";
import accountUserService from "./service/UserAccountService";
export default {
  mounted: function () {
    this.$router.afterEach((to, from) => {
      const isPublic = to.matched.some((record) => record.meta.public);
      this.eventCount++;
      if (!isPublic) {
        this.$_.delay(() => {
          if (this.eventCount == 1) {
            this.mini = true;
            this.eventCount = 0;
          } else {
            this.eventCount--;
          }
        }, 20000);
      }
    });
  },
  data() {
    return {
      hiddenToolbar: true,
      logoutModel: false,
      passwordModel: false,
      showOld: false,
      showNew: false,
      password: {},
      valid: true,
      passwordOldRule: [(v) => !!v || "Old Password is required"],
      passwordNewRule: [(v) => !!v || "New Password is required"],
      setting: {},
      limitDialog: false,
      loginUser: {},
      loadingCount: 0,
      loading: false,
      eventCount: 0,
      drawer: false,
      mini: true,
      profileList: [
        {
          title: "Change Password",
          icon: "mdi-lock",
          value: "/change_password",
        },
        {
          title: "Logout",
          icon: "mdi-logout",
          value: "/logout",
        },
      ],
      payments: [],
      reports: [],
      settings: [],
      orders: [],
      selectIndex:"home",
      selectedComputer:"home",
      dialogOsOrder:false
    };
  },
  props: {},
  mounted: function () {
    this.hiddenToolbar = false;
    this.loginMounted();
    axios.interceptors.request.use((config) => {
      this.loadingCount++;
      if (!this.loading) {
        this.loading = true;
      }
      return config;
    });
    axios.interceptors.response.use(
      (response) => {
        this.loadingCount--;
        if (this.loadingCount == 0 && this.loading) {
          this.loading = false;
        }
        return response;
      },
      (error) => {
        if (error.isAxiosError && error.message == "Network Error") {
          this.$swal("အင်တာနက် လိုင်းမကောင်းပါ", error.message, "error");
          this.loadingCount = 1;
          this.loading = false;
        }
        if (error.response.data != null && error.response.data.status == 401) {
          this.$swal(
            "Authentication Error",
            "ထွက်မည် ခလုတ်နှိပ်ပေးပါ",
            "error"
          );
          this.changeRouter("/logout");
          this.logout();
        }
        this.loadingCount--;
        if (this.loadingCount == 0 && this.loading) {
          this.loading = false;
        }
        return Promise.reject(error);
      }
    );
  },
  computed: {
    showComputer() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return true;
        case "lg":
          return true;
        case "xl":
          return true;
      }
    },
    isLogin: function () {
      let token = this.$store.state.userAccount.password;
      if (token == "") {
        return false;
      } else {
        axios.defaults.headers.common["Authorization"] = `deli${token}`;
        return true;
      }
    },
  },
  methods: {
    clickDrawer: function (item) {
      this.drawer = false;
      this.changeRouter(item.value);
    },
    changePassword: function () {
      accountUserService
        .changePassword(this.password.old, this.password.new)
        .then((response) => {
          if (response > 0) {
            this.$swal("Successful", "Change Password", "success");
            this.logout();
          } else {
            this.$swal("unSuccessful", "Change Password", "error");
          }
          this.password = {
            old: "",
            new: "",
          };
        });
      this.passwordModel = false;
    },
    cancelChangePassword: function () {
      this.passwordModel = false;
      this.password = {};
    },
    hideToolbar: function (hide) {
      this.hiddenToolbar = hide;
      this.loginMounted();
    },
    changeRouter: function (path) {
      if (path == "/logout") {
        this.logoutModel = true;
      } else if (path == "/change_password") {
        this.passwordModel = true;
      } else {
        this.$router
          .push({
            path: path,
          })
          .catch(() => {});
      }
    },
    logout: function () {
      this.$store.commit("updateUserAccount", {
        userName: "",
        password: "",
        role: "",
      });
      this.changeRouter("/os/login");
      this.logoutModel = false;
      this.hiddenToolbar = true;
    },
    loginMounted() {
      if (this.isLogin) {
        this.loginUser = this.$store.state.userAccount;
        if(this.loginUser.role!="OS"){
              this.$router.push("/os/onesignal").catch(() => {});
            }
        this.hiddenToolbar = false;
      } else {
        this.hiddenToolbar = true;
        this.$router.push("/os/login").catch(() => {});
      }
    },
    
  },
  watch: {
    isLogin(value) {
      if (value) {
        OneSignal.push(() => {
          accountUserService.getOnesignalAccount().then((data) => {
            OneSignal.setExternalUserId(data);
          });
        });
      } else {
        OneSignal.push(function () {
          OneSignal.removeExternalUserId();
        });
      }
    },
     
  },
  components: {osOrderDialog},
  name: "App",
};
</script>
<!-- <style lang="scss">
@import "sass/main.scss";
</style> -->
<style scope>
html::-webkit-scrollbar {
  width: 0px;
}

html::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.699);
  border-radius: 3px;
}

html::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: #4caf50;
  padding: 3px;
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
}

div::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.699);

  border-radius: 3px;
}

div::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: #4caf50;
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.5);
}
</style>